import React from "react"
import { Link } from '@components/Link'

import * as css from "@css/components/blocks/Error.module.styl"
// import Error404Svg from "@images/error__img__404.svg"

export default function Error404()
{
	return(
		<div className={css.container}>
			<div className={css.error}>
				{/* <Error404Svg viewBox="0, 0, 572, 287"/> */}
				<h1>404 NOT FOUND</h1>
				<p>お探しのページはありません</p>
				<div className={css.buttons}>
					<Link className={css.item} to="/">TOP</Link>
				</div>
			</div>
		</div>
	)
}
